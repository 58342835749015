<template>
  <button
    class="button"
    type="button"
    @click="$emit('action')"
    :class="size"
  >
    <slot></slot><img src="../assets/images/icons/right-arrow.svg" alt="Right arrow">
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: ['size'],
};
</script>

<style scoped>
.button {
  position: relative;

  display: flex;
  align-items: center;

  padding: 2rem 0 2rem 3.5rem;
  border-radius: 2.5rem;
  border: none;

  font-family: 'Helvetica Bold', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;

  color: #F3E0D6;
  background: #F76B1C;
  outline: none;
  cursor: pointer;
}

.button:active {
  background: #da6220;
}

.button img {
  position: absolute;
  top: 50%;
  right: 1.8rem;
  transform: translateY(-50%);

  width: 2.125rem;
}

.lg {
  width: 37%;
  padding: 2.5rem 0 2.5rem 7.3rem;

  font-size: 3rem;
}

.lg img {
  right: 2.8rem;

  width: 2.7rem;
}

.md {
  width: 48.3%;
}

@media (max-width: 991px) {
  .button {
    font-size: 2.5rem;
  }
}

@media (max-width: 668px) {
  .button {
    padding-block: 1.4rem;
  }
}
</style>
