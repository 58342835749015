<template>
  <main class="home">
    <div class="container">
        <section class="left">
          <div class="logo">
            <img src="../assets/images/logo.svg" alt="Home logo">
          </div>
          <div class="content">
            <p class="text">
              Explore how new Checkout and Returns protocols
              improve customer service while protecting against shrink.
            </p>
            <Button @action='$router.push("/game")' size="md">Get started</Button>
          </div>
        </section>
        <section class="right">
          <img src="../assets/images/peeps.svg" alt="Peeps">
        </section>
    </div>
  </main>
</template>

<script>
import Button from '../components/Button.vue';

export default {
  name: 'Home',
  components: {
    Button,
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 100vh;

  background: #F3E0D6;
}

.container {
  display: flex;
  justify-content: space-between;

  max-width: 130rem;
  padding-inline: 1.5rem;
  margin-inline: auto;
}

.left {
  width: 50%;
  padding-top: 9.1rem;
}

.right {
  width: 50%;
}

.content {
  padding-left: 3rem;
  margin-top: 8.2rem;
  width: 67%;
}

.text {
  margin-bottom: 11rem;

  color: #19343B;

  font-size: 2.25rem;
}

.logo img,
.right img {
  display: block;
  max-width: 100%;
  height: auto;
}

@media (max-width: 991px) {
  .left {
    padding-top: 10rem;
  }

  .content {
    width: 100%;
    margin-top: 5.2rem;
  }

  .content > button {
    width: 45%;
  }

  .text {
    margin-bottom: 6rem;

    font-size: 3rem;
  }

  .logo img {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .text {
    margin-bottom: 5rem;
  }

  .logo img {
    max-width: 75%;
  }
}

@media (max-width: 668px) {
  .content {
    padding-left: 2rem;
  }
}

</style>
